import { message } from '@repo/common/components';
import { AxiosError, isAxiosError } from 'axios';
import { signOut } from 'firebase/auth';
import { i18nInstance } from '@/app/[lang]/i18n/useTranslation';
import { auth } from '@/firebaseConfig';

export interface APIError {
  detail: {
    code: string;
    message: string;
    error_uid: string;
  }
}

export function handleAuthError(e: AxiosError) {
  if (e.response?.status === 401) {
    signOut(auth).then(() => {
      window.location.assign('/');
    });
  }
}

export class UserMessageError extends Error {}

interface ErrorProps {
  error: unknown;
  fallbackHandler?: (err: unknown) => unknown;
  displayMessage?: boolean;
}

export default async function handleError({ error, fallbackHandler, displayMessage }: ErrorProps) {
  const { t } = await i18nInstance;

  if (isAxiosError<APIError>(error)) {
    handleAuthError(error);
    if (error.response?.data) {
      const axiosError = error;
      const translation = axiosError.response ? t(axiosError.response.data.detail.code) : undefined;
      const messageContent = translation !== axiosError.response?.data.detail.code
        ? translation
        : error.response?.data.detail.message;
      if (displayMessage) message.error(messageContent || t('request-failed-try-again'));
      else return new UserMessageError(messageContent);
    }
  } else if (fallbackHandler) {
    fallbackHandler(error);
  }
  return null;
}
